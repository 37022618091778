export const geolocationApiKey = 'xxtD86y5TVCXPNo7PAOH7NHImkvw7EUvBIKYEOm6Kv4'

export const phCountryName = "Philippines"
export const thCountryName = "Thailand"
export const vnCountryName = "Vietnam"
export const sgCountryName = "Singapore"
export const idCountryName = "Indonesia"
export const myCountryName = "Malaysia"

export const phCountryISO = "PH"
export const thCountryISO = "TH"
export const vnCountryISO = "VN"
export const sgCountryISO = "SG"
export const idCountryISO = "ID"
export const myCountryISO = "MY"

//SG redirections set to MY=>as informed by client

//Whiskas redirections
export const whiskas_ph = "https://www.whiskas.com.ph/"
export const whiskas_th = "https://www.whiskas.co.th/"
export const whiskas_vn = "https://www.whiskas.com.vn/"
export const whiskas_sg = "https://www.whiskas.com.my/"
export const whiskas_id = "https://www.whiskasindonesia.com/"
export const whiskas_my = "https://www.whiskas.com.my/"

//Temptations redirections
export const temptations_ph = "https://www.temptationstreats.com.ph/"
export const temptations_th = "https://www.temptations.co.th/"
export const temptations_vn = "https://www.temptations.com.vn/"
export const temptations_sg = "https://www.temptations.my/"
export const temptations_id = "https://www.temptations.id/"
export const temptations_my = "https://www.temptations.my/"

//Sheba redirections 
export const sheba_ph = "https://www.sheba.com.ph/"
export const sheba_th = "https://www.sheba.co.th/"
//Sheba VN url missing==>hence redirecting to PH
export const sheba_vn = "https://www.sheba.com.ph/"
export const sheba_sg = "https://www.sheba.com.my/"
export const sheba_id = "https://www.shebacat.co.id/"
export const sheba_my = "https://www.sheba.com.my/"

//IAMS redirections 
export const iams_ph = "https://ph.iams.asia/"
export const iams_th = "https://th.iams.asia/"
export const iams_vn = "https://ph.iams.asia/"
export const iams_sg = "https://sg.iams.asia/"
export const iams_id = "https://id.iams.asia/"
export const iams_my = "https://my.iams.asia/"


