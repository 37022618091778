//Function to setCookie, pass cookie name as value as json
export const setCookie = (name, json) => {
    let cookieValue = '';
    //let expire = 0;

    //Specify the cookie name and value
    cookieValue = name + '=' + JSON.stringify(json) + ';';

    //Specify the path to set the cookie
    cookieValue += 'domain=.marscatnutrition.com; path=/ ;';

    //Specify how long you want to keep cookie--set as a session cookie
    //cookieValue += 'expires=' + expire + ';';

    //Set cookie
    document.cookie = cookieValue;
};


//Function to get Cookie pass cookie name as param
export function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}


//Function to delete cookie => pass cookie name and path='/' and change date to cookie expiration date
export function delete_cookie(name, path) {
    document.cookie = name +
        ((path) ? ";path=" + path : "") +
        ";expires=Thu, 25 May 2023 00:00:01 GMT";
}

//Function to set IP address to format : "XXX.XXX.XXX." => Exclude last section
export const setIPaddressFormat = (ip) => {
    let myIP = ip;
    let result = myIP.split('.').slice(0, 3).join('.');
    let index = result.length;
    let newIpAddress = result.slice(0, index) + ".";
    return newIpAddress;
}

//Function to redirect to external url, pass the URL as param
export const handleRedirection = (url) => {
    const timeout = setTimeout(() => {
        // 👇️ redirects to an external URL
        window.location.assign(url);
    }, 500);
    console.log("Button clicked!");
    return () => clearTimeout(timeout);
}

//Function to fetch user's device type
export function isMobile() {
    // const userAgent = navigator.userAgent.toLowerCase();
    // const isMobileUserAgent = /mobile|iphone|ipod|android|blackberry|opera mini|windows phone|iemobile/i.test(userAgent);
    const isMobileDevice = window.matchMedia("(max-width: 767px)").matches;
    return window.innerWidth < 768 || isMobileDevice; 
}
