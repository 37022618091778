// axiosConfig.js

import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://atlas.microsoft.com',          // Azure Maps API base URL
    headers: {
        'Content-Type': 'application/geo+json',
        'Access-Control-Allow-Origin': '*'
        // 'Ocp-Apim-Subscription-Key': 'xxtD86y5TVCXPNo7PAOH7NHImkvw7EUvBIKYEOm6Kv4', // Azure Maps API key
        // 'x-ms-client-id': '3bf18ff0-0e09-41e3-bb7f-4272ecfc8277', //  client ID
    },

});


export default instance;

