import React from "react";

function Button(props) {
    const centerImage = props.centerImage || false;
    // const labelText = props.labelText;
    return (
        <div onClick = {props.onClick} className={props.className} >
        {/* <button onClick = {props.onClick} className={props.className} >
            {centerImage ? <img style={props.imageStyle} src={props.imageURL} alt={props.imageAlt} /> : null}
            {labelText ? props.label : null}
        </button> */}
        {centerImage ? <img className= {props.imageStyle} src={props.imageURL} alt={props.imageAlt} />: null}
        </div>
    )
}

export default Button;