import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import Catpage from './Catpage'

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route index element={<Catpage />} />

                {/* <Route path={"/catnutrition"} exact element={<Catpage/>} /> */}

            </Routes>
        </Router>
    );
}

export default AppRouter; 