import React, {useState, useEffect} from 'react';
import './DropdownComponent.css';
import 'bootstrap/dist/css/bootstrap.min.css';  
import * as Constants from '../util/constant'; 


function DropdownComponent(props){
    const [selectedOption, setSelectedOption] = useState("");
  
    const options = [
      { value: Constants.thCountryName, label: Constants.thCountryName, iso: Constants.thCountryISO },
      { value: Constants.myCountryName, label: Constants.myCountryName, iso: Constants.myCountryISO },
      { value: Constants.sgCountryName, label: Constants.sgCountryName, iso: Constants.sgCountryISO },
      { value: Constants.phCountryName, label: Constants.phCountryName, iso: Constants.phCountryISO },
      { value: Constants.idCountryName, label: Constants.idCountryName, iso: Constants.idCountryISO },
      { value: Constants.vnCountryName, label: Constants.vnCountryName, iso: Constants.vnCountryISO }

    ];
  
    const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
      const selectedLabel = options.find(
        (option) => option.value === selectedValue
      ).label;
      const selectedOption = options.find(
        (option) => option.value === selectedValue
      );
      setSelectedOption(selectedLabel);
      props.onCountryChange(selectedOption.value, selectedOption.iso);
    };
  
    useEffect(() => {
      setSelectedOption(props.initialValue);
    }, [props.initialValue]);

    return (
        <div className='d-flex align-items-center'>
            <select 
                id='dropdown'
                value={selectedOption}
                onChange={handleSelectChange}
                style={{ backgroundColor: '#0d2958', marginLeft: "10px", color: "white" }}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    )


}

export default DropdownComponent;